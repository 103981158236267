import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
@Injectable()
export class PosSensorsService {
  url = environment.url;

  constructor(private http: HttpClient, private matSnackBar: MatSnackBar) {}

  // Zwraca listę POS
  getPosList() {
    return this.http.get<{ data: any[] }>(`${this.url}/pos/list`);
  }

  // Zwraca szczegóły danego POS
  getPosDetails(idaps) {
    return this.http.get<{ data: any[] }>(`${this.url}/pos/details/${idaps}`);
  }

  // Zwraca ogólne statystyki
  getPosStats(begin, end, idaps) {
    return this.http.get<{ data: any[] }>(
      `${this.url}/pos/stats/${idaps}?&begin=${begin}&end=${end}`
    );
  }

  // Zwraca statystyki na potrzeby wykresu
  getPosChart(period, begin, end, idaps) {
    return this.http.get<{ data }>(
      `${this.url}/pos/chart/${idaps}?&period=${period}&begin=${begin}&end=${end}`
    );
  }

  // Umożliwia aktualizację pojedynczego lub wielu punktów pos
  posUpdate(posDetails): Observable<any> {
    return this.http.post<any>(`${this.url}/pos/update-pos`, posDetails).pipe(
      tap(res => {
        if (!res.success) {
          this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
            duration: 5000
          });
        } else {
          this.matSnackBar.open('Zaktualizowano', 'Zamknij', {
            duration: 5000
          });
        }
      })
    );
  }

  // Zwraca listę urządzeń
  getSensorList() {
    return this.http.get<{ data: any[] }>(`${this.url}/sensor/list`);
  }

  // Umożliwia aktualizację pojedynczego lub wielu sensorów
  sensorUpdate(sensorDetails): Observable<any> {
    return this.http
      .post<any>(`${this.url}/sensor/update-sensor`, sensorDetails)
      .pipe(
        tap(res => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
              duration: 5000
            });
          } else {
            this.matSnackBar.open('Zaktualizowano', 'Zamknij', {
              duration: 5000
            });
          }
        })
      );
  }

  // Zwraca listę statusów funkcjonujących w systemie
  posStatusList() {
    return this.http.get<{ data: any[] }>(`${this.url}/pos/status-list`);
  }

  // Umozliwia przekazanie pliku excel na serwer w celu aktualizacji danych punktow pos
  updatePosFromFile(file) {
    return this.http
      .post<any>(`${this.url}/pos/update-pos-from-file`, file)
      .pipe(
        tap(res => {
          if (!res.success) {
            this.matSnackBar.open('Wystąpił błąd', 'Zamknij', {
              duration: 5000
            });
          } else {
            this.matSnackBar.open('Punkty zostały zaktualizowane', 'Zamknij', {
              duration: 5000
            });
          }
        })
      );
  }

  // Zwraca listę parametrów softu i modelu
  getParamsList() {
    return this.http.get<{ data: any[] }>(`${this.url}/sensor/params-list`);
  }
}
