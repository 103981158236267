export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {}
    },
    pos: {
      page: {
        title: 'POS',
        link: '/pos'
      }
    },
    diagnostics: {
      page: {
        title: 'Diagnostyka',
        link: '/diagnostics'
      }
    },
    sensors: {
      page: {
        title: 'Sensory',
        link: '/sensors'
      }
    },
    kr: {
      page: {
        title: 'KR',
        link: '/kr'
      }
    },
    rks: {
      page: {
        title: 'RKS',
        link: '/rks'
      }
    },
    profile: {
      page: { title: 'Ustawienia konta' }
    },
    mail: {
      page: { title: 'Mail', desc: 'Mail' }
    },

    error: {
      404: {
        page: { title: '404 Not Found', desc: '', subheader: false }
      },
      403: {
        page: { title: '403 Access Forbidden', desc: '', subheader: false }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
