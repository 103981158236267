// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d578345767sdgjkhdsg77744',
  url: 'http://dev.app.isensor.pl/api',
  // url: 'https://app.isensor.pl/api',
  //url: 'http://localhost/isensor_v2/isensor-api/public_html/api',
  panelUrl: 'https://panel.isensor.pl',
  base: 'https://app.isensor.pl'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
