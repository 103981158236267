// Angular
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';

import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PosSensorsService } from '../../../../services/pos-sensors.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

moment.locale('pl');

@Component({
  selector: 'kt-update-pos-comment',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class UpdatePosComponent implements OnInit, OnDestroy {
  updatePosForm: FormGroup;
  errors: any = [];
  // id i adres przekazywane przy wywoływaniu modala
  idApsArray: any;

  // Nowy status
  status: any = '';
  // Nowy status PS
  posStatus: any = '';
  // Nowy status licznika w PS - komentarz
  posStatusDesc: any = '';

  // Notatka
  comment: any;

  // sprawdza czy edytowany jest tylko jeden wiersz
  single: boolean;

  // Tablica na potrzeby przechowywania listy statusów z identyfikatorami
  posList: any = [];

  // Wywoływanie spinnera jezeli dane nie są jeszcze wczytane
  loading: any;

  constructor(
    public activeModal: NgbActiveModal,
    private posSensorsService: PosSensorsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    console.log(this.status);
    this.posStatusList();
    this.initRegistrationForm();
  }
  initRegistrationForm() {
    this.updatePosForm = this.fb.group({
      status: [''],
      posStatus: [''],
      posStatusDesc: ['']
    });
  }
  // Pobranie listy statusów
  posStatusList() {
    this.posSensorsService.posStatusList().subscribe(res => {
      this.posList = res;
    });
  }
  updatePos() {
    const controls = this.updatePosForm.controls;
    /** check form */
    if (this.updatePosForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    } else {
      const posDetails = {
        // posComment: this.updatePosForm.value.comment,
        posIds: this.idApsArray,
        statusId: this.status.statusId,
        posStatusId: this.posStatus.statusId,
        posStatusDescId: this.posStatusDesc.statusId
      };
      this.posSensorsService.posUpdate(posDetails).subscribe(res => {
        this.activeModal.close('PosUpdate');
      });
    }
  }
  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.updatePosForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  ngOnDestroy() {
    this.idApsArray = [];
  }
}
