import * as moment from 'moment';
import * as XLSX from 'xlsx';
// RXJS
import { startWith, tap } from 'rxjs/operators';
// Angular
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// Serwisy
import { PosSensorsService } from '../../../../services/pos-sensors.service';
import { TableService } from '../../../../services/table.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { ToastService } from '../../../../services/toast.service';
// Okno modalne
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Helper
import { labelClass } from '../../../../helpers/labelClass';
// Komponenty
import { UpdatePosComponent } from '../updatePos/pos.component';
import { SelectionModel } from '@angular/cdk/collections';
@Component({
  selector: 'kt-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
  @ViewChild(MatPaginator, {
    static: true
  })
  paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: true
  })
  // sortowanie kolumn
  sort: MatSort;
  // na potrzeby przekazania danych z serwera do tabeli
  dataSource: any;

  // formGroup w celu zapamiętywania wartości pobranych z local storage do filtrów w tabeli
  posFormGroup: FormGroup;

  // DLA IMPORTU PLIKU EXCEL //

  // nazwa arkusza Excel
  sheetName: any;
  // tablica filtrów na potrzeby importowania pliku excel
  sfidFilters: any = [];
  // Rodzaj widoku filtra - inny dla osób, które wybrały plik excel
  tableView: any;
  // Domyślny brak dostępu do zastrzezonego dodawania filtrów do tabeli
  restrictedToOperatorAccess = false;

  // ________ //

  // DLA ZAZNACZANIA WIERSZY W TABELI //

  // wybrane wiersze
  selection: any;

  initialSelection: any = [];

  // ________ //

  // Suma wszystkich sensorów
  sumSensorsCount: any;
  // Średnie wartości kolumn
  avgReportingDaysPercentage: any;
  avgReportsCountPercentage: any;

  // zachowywanie szczegolow posa w local storage
  retrievePosDetails: any;
  // kolumny, które są wyswietlanie w tabeli - usunięcie ukryje kolumnę, jednak bedzie trzeba dostosowac jej szerokosc w css
  columnsToDisplay = [
    'select',
    'index',
    'sfid',
    'network',
    'address',
    'email',
    'phone',
    'gallery',
    'status',
    'posStatus',
    'posStatusDesc',
    'extendedStatus',
    'sensorsCount',
    'reportingDaysPercentage',
    'reportsCountPercentage',
    'actions'
  ];
  // obiekt do którego przypisywane są wartości z local storage
  pos = {
    sfid: [''],
    address: '',
    network: '',
    email: '',
    phone: '',
    gallery: '',
    status: [''],
    posStatus: '',
    posStatusDesc: '',
    extendedStatus: '',
    sensorsCount: '',
    reportingDaysPercentage: '',
    reportsCountPercentage: ''
  };

  // pola, które mogą zostać wpisane z wielkich liter
  allowedUppercaseFilters = ['address', 'email'];

  // obiekt przechowujący listę punktów pos
  posList: any = [];

  // obiekt przechowujący listę statusów
  posStatusArray: any = [];

  constructor(
    private cd: ChangeDetectorRef,
    private tableService: TableService,
    private posSensorsService: PosSensorsService,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    // na potrzeby wyboru wierszy w tabeli
    const allowMultiSelect = true;
    this.selection = new SelectionModel(
      allowMultiSelect,
      this.initialSelection
    );
  }

  /**
   * Init
   */

  ngOnInit() {
    this.selection.clear();
    // pobranie listy posów
    this.getPosList();
    // pobranie listy statusów
    this.posStatusList();

    this.posFormGroup = new FormGroup({
      sfidFilter: new FormControl(''),
      addressFilter: new FormControl(''),
      networkFilter: new FormControl(''),
      emailFilter: new FormControl(''),
      phoneFilter: new FormControl(''),
      galleryFilter: new FormControl(''),
      statusFilter: new FormControl(''),
      posStatusFilter: new FormControl(''),
      posStatusDescFilter: new FormControl(''),
      extendedStatusFilter: new FormControl(''),
      sensorsCountFilter: new FormControl(''),
      reportingDaysPercentageFilter: new FormControl(''),
      reportsCountPercentageFilter: new FormControl('')
    });
    // na potrzeby importu pliku
    this.tableView = 'filters';
    // zrodlo danych dla tabeli i ustawienia
    this.dataSource = new MatTableDataSource();
    this.dataSource.filterPredicate = this.createFilter(this.tableView);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // pobranie roli uzytkownika
    this.getRole();

    // jezeli nie ma w storage POS, to utwórz z pustymi wartościami
    if (!localStorage.getItem('pos')) {
      localStorage.setItem('pos', JSON.stringify(this.pos));
    }

    this.retrievePosDetails = JSON.parse(localStorage.getItem('pos'));
    // jezeli w local storage jest tablica sfid
    if (this.retrievePosDetails.sfid) {
      if (Array.isArray(this.retrievePosDetails.sfid)) {
        if (this.retrievePosDetails.sfid.length > 1) {
          // utworz inny widok pola wyboru w tablicy - select multiple
          this.tableView = 'excel';
          // przypisanie wartosci z local storage do filtra
          this.sfidFilters = this.retrievePosDetails.sfid;
          // utworzenie filtra
          this.dataSource.filterPredicate = this.createFilter(this.tableView);
        }
      }
    }
  }

  /**
   * Dodawanie właściwości do obiektu POS
   */

  addPosDetailsToLocalStorage() {
    localStorage.setItem('pos', JSON.stringify(this.pos));
  }

  /**
   * Funkcja odpowiadająca za pobranie roli z tokena
   */

  getRole() {
    this.authService.getRole().subscribe(roles => {
      console.log(roles);
      if (roles.includes('operator')) {
        this.restrictedToOperatorAccess = true;
      }
    });
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Sprawdzanie czy zostały zaznaczone wszystkie wiersze
   */

  isAllSelected() {
    return this.tableService.isAllSelected(this.selection, this.dataSource);
  }

  /**
   * Na potrzeby zaznaczania wierszy w tabeli - Funkcjonalność głównego włącznika
   */

  masterToggle() {
    return this.tableService.masterToggle(this.selection, this.dataSource);
  }

  /**
   * Funkcja odpowiadająca za zresetowanie filtrów
   */

  resetFilter() {
    this.dataSource.filter = '';
    this.posFormGroup.patchValue({
      sfidFilter: [''],
      addressFilter: '',
      networkFilter: '',
      emailFilter: '',
      phoneFilter: '',
      galleryFilter: '',
      statusFilter: [''],
      posStatusFilter: '',
      posStatusDescFilter: '',
      extendedStatusFilter: '',
      sensorsCountFilter: '',
      reportingDaysPercentageFilter: '',
      reportsCountPercentageFilter: ''
    });
    // przypisanie wartosci z local storage do filtra
    this.sfidFilters = [];
    // Powrót do domyślnego filtrowania
    this.tableView = 'filters';
    this.dataSource.filterPredicate = this.createFilter(this.tableView);
  }

  /**
   * Funkcja odpowiadająca za ustanowienie filtrów
   */

  // setValue polega na ustawieniu wartości w polu szukania
  // startWith uruchamia inicjalnie filtr z podaną wartością
  setFilters(allowedUppercaseFilters) {
    // pola, które mogą zostać wpisane z wielkich liter
    for (const [index, [key, value]] of Object.entries(
      Object.entries(this.pos)
    )) {
      this.posFormGroup
        .get(Object.keys(this.posFormGroup.controls)[index])
        .setValue(this.retrievePosDetails[key]);
      this.posFormGroup
        .get(Object.keys(this.posFormGroup.controls)[index])
        .valueChanges.pipe(startWith(this.retrievePosDetails[key]))
        .subscribe(value => {
          if (allowedUppercaseFilters.includes(key)) {
            this.pos[key] = value.toLowerCase();
          } else {
            this.pos[key] = value;
          }
          // this.sumSensorsCount
          this.dataSource.filter = JSON.stringify(this.pos);
          this.sumSensorsCount = this.dataSource.filteredData.reduce(
            (sCSum, v) => (sCSum += parseInt(v.sensorsCount)),
            0
          );
          // https://stackoverflow.com/questions/6736476/how-to-turn-nan-from-parseint-into-0-for-an-empty-string
          this.avgReportingDaysPercentage =
            this.dataSource.filteredData.reduce(
              (aRSum, v) => (aRSum += parseInt(v.reportingDaysPercentage) || 0),
              0
            ) / this.dataSource.filteredData.length;
          this.avgReportsCountPercentage =
            this.dataSource.filteredData.reduce(
              (aRSum, v) => (aRSum += parseInt(v.reportsCountPercentage) || 0),
              0
            ) / this.dataSource.filteredData.length;
          this.addPosDetailsToLocalStorage();
        });
    }
  }

  /**
   * Mozliwosc importowania pliku Excel na potrzeby konta Operator
   */

  applyFiltersByExcel(ev) {
    this.tableService.importExcelFile(
      ev,
      this.posFormGroup,
      this.pos,
      this.sfidFilters
    );
    this.tableView = 'excel';
    this.dataSource.filterPredicate = this.createFilter(this.tableView);
    ev.target.value = '';
    this.selection.clear();
  }

  /**
   * Funkcja umozliwia zaimportowanie pliku Excel w celu aktualizacji wartosci posów
   */

  importExcelToUpdatePos(ev) {
    this.tableService.importExcelToUpdatePos(ev);
    ev.target.value = '';
  }

  /**
   * Funkcja dostosowująca widok labela prezentującego ostatni raport w zaleznosci od roznicy czasu
   * @param lastReport
   */

  labelColor(lastReport) {
    return labelClass(lastReport);
  }

  /**
   * Funkcja zwracająca listę statusów
   */

  posStatusList() {
    this.posSensorsService.posStatusList().subscribe(res => {
      this.posStatusArray = res;
    });
  }

  /**
   * Funkcja aktualizująca filtry w tabeli
   */

  createFilter(tableView): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      let statusSearch = () => {
        let found = false;
        // jezeli nie został wybrany zaden model to filtruj domyslnie
        if (searchTerms.status == '' || searchTerms.status == '-') {
          found = true;
        }
        searchTerms.status.forEach(option => {
          // perfect match
          if (data.status.toLowerCase() == option) {
            found = true;
          }
        });
        return found;
      };
      let sfidSearch = () => {
        // pole wyszukiwania SFID - inne w zaleznosci od tego czy wybieramy filtry z pliku, czy wpisujemy ręcznie
        if (tableView == 'filters') {
          return data.sfid.indexOf(searchTerms.sfid) !== -1;
        } else {
          let foundSfid = false;
          // jezeli nie został wybrany zaden model to filtruj domyslnie
          if (searchTerms.sfid == '') {
            foundSfid = true;
          }
          searchTerms.sfid.forEach(option => {
            if (data.sfid.indexOf(option) !== -1) {
              foundSfid = true;
            }
          });
          return foundSfid;
        }
      };
      try {
        return (
          sfidSearch() &&
          statusSearch() &&
          data.address
            .toString()
            .toLowerCase()
            .indexOf(searchTerms.address) !== -1 &&
          data.network.indexOf(searchTerms.network) !== -1 &&
          data.email.indexOf(searchTerms.email) !== -1 &&
          data.phone.indexOf(searchTerms.phone) !== -1 &&
          data.gallery.indexOf(searchTerms.gallery) !== -1 &&
          data.posStatus.indexOf(searchTerms.posStatus) !== -1 &&
          data.posStatusDesc.indexOf(searchTerms.posStatusDesc) !== -1 &&
          data.extendedStatus.indexOf(searchTerms.extendedStatus) !== -1 &&
          JSON.stringify(data.sensorsCount).indexOf(
            searchTerms.sensorsCount
          ) !== -1 &&
          data.reportingDaysPercentage.indexOf(
            searchTerms.reportingDaysPercentage
          ) !== -1 &&
          data.reportsCountPercentage.indexOf(
            searchTerms.reportsCountPercentage
          ) !== -1
        );
      } catch (err) {}
    };
    return filterFunction;
  }

  /**
   * Pobieranie listy POS
   */

  getPosList() {
    this.posSensorsService
      .getPosList()
      .pipe(
        tap({
          complete: () => this.setFilters(this.allowedUppercaseFilters)
        })
      )
      .subscribe(res => {
        this.posList = res.data;
        this.dataSource.data = this.posList;
        // jezeli jakakolwiek wartosc jest null lub ''
        this.posList.forEach(value => {
          Object.keys(value).forEach(key => {
            if (value[key] === '' || value[key] === null) {
              value[key] = '-';
            }
          });
        });
        this.cd.detectChanges();
      });
  }

  /**
   * Masowa edycja POSow na podstawie pola sfid
   */

  updateAllPos() {
    // var idApsArray = [];
    // this.dataSource.filteredData.map(row => {
    //   idApsArray.push(row.idaps);
    // });

    // const modalRef = this.modalService.open(UpdatePosComponent, {
    //   size: 'lg'
    // });
    // // przekazywanie tablicy sfid
    // console.log(idApsArray);
    // modalRef.componentInstance.idApsArray = idApsArray;
    // // pobranie listy w przypadku pomyślnej edycji
    // modalRef.result.then(result => {
    //   this.getPosList();
    // });
    if (this.selection.selected.length == 0) {
      this.toastService.displayToast('Brak zaznaczonych wierszy w tabeli');
    } else {
      // Wyodrębnienie sfid z wybranego wiersza i utworzenie tablicy
      var idApsArray = [];
      this.selection.selected.map(row => {
        idApsArray.push(row.idaps);
      });
      const modalRef = this.modalService.open(UpdatePosComponent, {
        size: 'lg'
      });
      // przekazywanie tablicy sfid
      modalRef.componentInstance.idApsArray = idApsArray;
      // pobranie listy w przypadku pomyślnej edycji
      modalRef.result.then(result => {
        if (result) {
          this.ngOnInit();
        }
      });
    }
  }

  /**
   * Edycja szczegółów POS w oknie modalnym
   */

  updatePosDetails(idaps, status, single) {
    const modalRef = this.modalService.open(UpdatePosComponent, {
      size: 'lg'
    });
    // przekazywanie adresu i id urządzenia
    modalRef.componentInstance.idApsArray = [idaps];
    modalRef.componentInstance.status = status;
    modalRef.componentInstance.single = single;
    // pobranie listy w przypadku pomyślnej edycji
    modalRef.result.then(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
